import { getLanguageCodeFromSession } from "helpers/CommonHelper";
import { Outlet, useLocation } from "react-router-dom";
import LoadingScreen from "../shared/LoadingScreen";
import Footer from "./Footer";
import Navbar from "./header/Navbar";
import BrevoChat from "web/views/brevo-chat/BrevoChat";

export default function MainLayout() {
  const { pathname } = useLocation();
  // console.log(pathname);
  return (
    <div>
      <LoadingScreen />
      {pathname !== "/" && pathname !== `/${getLanguageCodeFromSession()}/` && (
        <Navbar />
      )}
      <div className="mobile-fix-option"></div>
      <Outlet />
      {/* <BrevoChat /> */}
      <Footer layoutLogo="layout-2" />
    </div>
  );
}
